body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html, #root {
  margin: 0;
  padding: 0;
  background-color: #181818;
  width: 100vw;
  height: 100vh;
  color: #eee;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-size: large;
  background-color: #333;
  outline: none;
  border: none;
  color: #eee;
  padding: 8px 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s;
}

button:hover {
  background-color: #444;
}

.clickable:hover {
  filter: brightness(0.8);
}

@keyframes flip {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes red-to-white {
  0% {
    color: #FF4136;
  }

  50% {
    color: #FF4136;
  }

  100% {
    color: #eee;
  }
}


@keyframes green-to-white {
  0% {
    color: #2ECC40;
  }

  50% {
    color: #2ECC40;
  }

  100% {
    color: #eee;
  }
}
